import { SET_THEME } from 'actions/global'

export const DEFAULT_THEME = 'dark'

export interface GlobalState {
  theme: string
  userChosenTheme: boolean
}

const initialState = {
  theme: DEFAULT_THEME,
  userChosenTheme: false
}

export default (state: GlobalState = initialState, action) => {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.theme,
        userChosenTheme: true
      }
    default:
      return state
  }
}