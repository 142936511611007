import { API } from 'aws-amplify';
import {
  FETCH_PROFILE,
  FETCH_PROFILE_ERROR,
  FETCH_PROFILE_ERROR_NO_SUCH_PROFILE,
  FETCH_PROFILE_ITEMS_AND_COLLECTIONS_LOADING,
  FETCH_PROFILE_ITEMS_AND_COLLECTIONS_SUCCEED,
  FETCH_PROFILE_ITEMS_AND_COLLECTIONS_ERROR,
  FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_LOADING,
  FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_SUCCEED,
  FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_ERROR,
  profileItemAndCollectionsFetchLimit,
  State,
} from '../../reducers/user/viewProfile';
import { LOADINGOVERLAY } from '../loadingOverlay';
import { getItems } from '../../REST/items';
import { getItem } from '../../REST/items';
import { removeTopology } from '../../components/utils/removeTopology';
import { Item } from 'types/Item';
import { getCollections } from 'REST/collections';
import { getById } from 'REST/collections';
import { getItemsAndCollectionsForCollection } from 'components/utils/DetailPreview';
import { Collection } from 'types/Collection';
import addFilesToData from 'REST/utils/addFilesToData';
import {
  FETCH_PROFILE_STORIES_LOADING,
  FETCH_PROFILE_STORIES_SUCCEED,
  FETCH_PROFILE_STORIES_ERROR,
  FETCH_PROFILE_OWN_STORIES_LOADING,
  FETCH_PROFILE_OWN_STORIES_SUCCEED,
  FETCH_PROFILE_OWN_STORIES_ERROR
} from '../../reducers/user/viewProfile';
import { getStoriesAndTotalStoriesInDatabase } from '../../REST/story';
import { getCurrentAuthenticatedUser } from 'components/utils/Auth';
import { CognitoUser } from 'amazon-cognito-identity-js';

/**
 *
 * API call to fetch profile information based on the profileID and dispatch it through to Redux
 *
 * @param profileId {string}
 */
export const fetchProfile = (profileId: string, cognitoUser?: string) => async (dispatch, getState) => {
  const prevState = getState();
  // dispatch({ type: LOADINGOVERLAY, on: true }); // Turn on the loading overlay

  // Detect if we have the same profileID and return the previous state.
  // We do this here to stop another API call and you can easily get the prevState in the Action.
  if (prevState.viewItem.profile && profileId === prevState.viewItem.profile.profileId) {
    dispatch({ type: LOADINGOVERLAY, on: false }); // Turn off the loading overlay
    dispatch(fetchProfileItemsAndCollections());

  } else {
    try {
      const queryStringParameters = {
        id: profileId,
        cognito_user: cognitoUser || ''
      }

      const response = await API.get('tba21', 'profiles', { queryStringParameters });

      if (!!response.profile && response.profile.length && response.profile[0]) {
        const profile = response.profile[0];

        dispatch({
           type: FETCH_PROFILE,
           profile: profile
         });
      } else {
        dispatch({
           type: FETCH_PROFILE_ERROR_NO_SUCH_PROFILE,
           profile: {}
         });
      }
    } catch (e: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
      dispatch({
         type: FETCH_PROFILE_ERROR
       });
    } finally {
      dispatch({ type: LOADINGOVERLAY, on: false }); // Turn off the loading overlay
      dispatch(fetchProfileItemsAndCollections());
    }
  }
};

export const fetchProfileItemsAndCollections = () => async (dispatch, getState) => {
  const state = getState().viewProfile;

  if ((!state.itemsHasMore && !state.collectionsHasMore) || !state.profile?.cognito_uuid || state.isItemsAndCollectionsLoading) {
    return;
  }

  dispatch({ type: FETCH_PROFILE_ITEMS_AND_COLLECTIONS_LOADING });
  let uuid = state.profile.cognito_uuid;
  let limit = profileItemAndCollectionsFetchLimit;
  const itemQueries = {
    uuid,
    limit,
    offset: state.items.length,
  };

  const collectionQueries = {
    uuid,
    limit,
    offset: state.collections.length,
  };

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let promises: Promise<any>[] = [];

    if (state.itemsHasMore) {
      promises.push(getItems(itemQueries));
    } else {
      promises.push(Promise.resolve({}));
    }

    if (state.collectionsHasMore) {
      promises.push(getCollections(collectionQueries));
    } else {
      promises.push(Promise.resolve({}));
    }

    let [items, collections] = await Promise.all(promises);

    items = removeTopology(items, 'item') as Item[];
    collections = removeTopology(collections, 'collection') as Collection[];
    collections = await getItemsAndCollectionsForCollection(collections);

    let [itemsWithFile, collectionsWithFile] = await Promise.all([
      addFilesToData(items),
      addFilesToData(collections as Collection[])
    ]);

    dispatch({
      type: FETCH_PROFILE_ITEMS_AND_COLLECTIONS_SUCCEED,
      items: itemsWithFile,
      collections: collectionsWithFile
    });
  } catch {
    dispatch({
      type: FETCH_PROFILE_ITEMS_AND_COLLECTIONS_ERROR,
    });
  }
};

export const fetchProfileStories = () => async (dispatch, getState) => {
  const state: State = getState().viewProfile;
  if (!state.profile?.cognito_uuid) return
  dispatch({type: FETCH_PROFILE_OWN_STORIES_LOADING});
  try {
    let payload = await getStoriesAndTotalStoriesInDatabase({
      sticky: false,
      perPage: 100,
      page: 1,
      cognitoId: state.profile?.cognito_uuid
    });
    dispatch({type: FETCH_PROFILE_OWN_STORIES_SUCCEED, stories: payload.stories});
  } catch {
    dispatch({type: FETCH_PROFILE_OWN_STORIES_ERROR});
  }
};


export const fetchProfileFavouriteStories = () => async (dispatch, getState) => {
  const state: State = getState().viewProfile;
  if (!state.profile?.cognito_uuid || state.isFavouriteStoriesLoading) {
    return;
  }
  dispatch({type: FETCH_PROFILE_STORIES_LOADING});
  try {
    let storyIds = state.profile?.favourites?.stories ?? [];

    if (!storyIds || storyIds.length === 0) {
      return dispatch({ type: FETCH_PROFILE_STORIES_SUCCEED, stories: [] });
    }

    let payload = await getStoriesAndTotalStoriesInDatabase({
      sticky: false,
      include: storyIds
    });
    dispatch({type: FETCH_PROFILE_STORIES_SUCCEED, stories: payload.stories});
  } catch {
    dispatch({type: FETCH_PROFILE_STORIES_ERROR});
  }
};


export const fetchProfileFavouriteItemsAndCollections = () => async (dispatch, getState) => {
  const state = getState().viewProfile;
  if (!state.profile?.cognito_uuid || state.isFavouriteItemsAndCollectionsLoading) {
    return;
  }

  dispatch({ type: FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_LOADING });

  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let itemPromises: Promise<any>[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let collectionPromises: Promise<any>[] = [];
    const itemIds = state.profile?.favourites?.items;
    const collectionIds = state.profile?.favourites?.collections;

    let items, collections, itemsWithFile, collectionsWithFile, itemsWithFileUnpacked, collectionsWithFileUnpacked;
    if (itemIds && itemIds.length && itemIds.length > 0) {
      itemIds.map(id => itemPromises.push(getItem({id})));
      items = await Promise.all(itemPromises);
      items = items.map((item) => removeTopology(item, 'item'));
      itemsWithFile = await Promise.all(items.map(async (item) => await addFilesToData(item)));
      itemsWithFileUnpacked = itemsWithFile.map(item => item[0]).filter(item => item && item.id);
    } else {
      itemsWithFileUnpacked = [];
    }
    if (collectionIds && collectionIds.length && collectionIds.length > 0 ) {
      collectionIds.map(id => collectionPromises.push(getById(id)));
      collections = await Promise.all(collectionPromises);
      collections = await Promise.all(collections.map(async (collection) => await getItemsAndCollectionsForCollection(removeTopology(collection, 'collection'))));
      collectionsWithFile = await Promise.all(collections.map(async(collection) => await addFilesToData(collection)));
      collectionsWithFileUnpacked = collectionsWithFile.map(collection => collection[0]).filter(collection => collection && collection.id);
    } else {
      collectionsWithFileUnpacked = [];
    }

    dispatch({
      type: FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_SUCCEED,
      favouriteItems: itemsWithFileUnpacked,
      favouriteCollections: collectionsWithFileUnpacked,
    });
  } catch (e){
    console.error(e);
    dispatch({
      type: FETCH_PROFILE_FAVOURITE_ITEMS_AND_COLLECTIONS_ERROR,
    });
  }
};

export const initializeProfileData = (profileId: string, noOverlay) => async (dispatch, getState) => {
  !noOverlay && dispatch({ type: LOADINGOVERLAY, on: true }) // Turn on the loading overlay

  const cognitoUser = await getCurrentAuthenticatedUser()

  if (!profileId) return false

  await fetchProfile(profileId, cognitoUser && (cognitoUser as CognitoUser).getUsername() || '')(dispatch, getState)

  await Promise.all([
    fetchProfileFavouriteItemsAndCollections()(dispatch, getState),
    fetchProfileFavouriteStories()(dispatch, getState),
    fetchProfileStories()(dispatch, getState)
  ])

  dispatch({ type: LOADINGOVERLAY, on: false })

  return true
}

