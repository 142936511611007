import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { API } from 'aws-amplify'

import { Alerts, ErrorMessage, SuccessMessage } from '../../utils/alerts'

import { fetchPortfolios } from 'actions/user/portfolios'
import { PortfoliosState } from 'reducers/user/portfolios'
import { ProfileState } from 'reducers/user/profile'
import { toggleOverlay } from 'actions/loadingOverlay'
import { Portfolio } from 'types/Portfolio'

import PortfolioSearch from './PortfolioSearch'
import RollingHeader from 'components/layout/RollingHeader'
import ThemeSwitch from 'components/utils/ThemeSwitch'
import Blob from 'components/blob/Blob'
import { portfolioURL } from 'urls'
import { shuffleArray } from 'utils'

import './Portfolios.scss'

const Bubble = ({ portfolio }: { portfolio: Portfolio }) => (
  <Link to={ portfolioURL(portfolio.profile_id) } className="portfolios__bubble" key={ portfolio.id }>
    <Blob floating={ false } glow={ false } oneLine={ true } image={ portfolio.profile.profile_image || '' } />
  </Link>
)

interface Props extends RouteComponentProps, Alerts {
  publicPortfolios: Portfolio[]
  portfoliosState: PortfoliosState
  profileState: ProfileState
  fetchPortfolios: Function
  toggleOverlay: Function
}

interface State {
  loading: boolean
  errorMessage: string
  successMessage: string
  creatingPortfolio: boolean
  userHasPortfolio?: boolean
}

class Portfolios extends React.Component<Props, State> {
  userLoggedIn: boolean = false

  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
      errorMessage: '',
      successMessage: '',
      creatingPortfolio: false,
      userHasPortfolio: undefined
    }
  }

  async componentDidMount() {
    this.props.toggleOverlay(true)

    if (this.props.location.state?.successMessage) {
      this.setState({ successMessage: this.props.location.state.successMessage })
    }

    if (!this.props.portfoliosState.portfolios?.length) {
      await this.props.fetchPortfolios()
    }

    this.onUpdate()
  }

  componentDidUpdate() {
    this.onUpdate()
  }

  onUpdate = () => {
    if (this.state.loading) {
      const finishedLoading = !this.props.portfoliosState.loading && !this.props.profileState.overlay
      finishedLoading && this.setState({ loading: false })
    } else {
      this.checkUser()
    }
  }

  checkUser = () => {
    if (this.state.userHasPortfolio !== undefined) return

    this.userLoggedIn = !!this.props.profileState.details

    if (!this.userLoggedIn || !this.props.portfoliosState.portfolios.length) {
      this.setState({ userHasPortfolio: false })
    } else {
      const found = this.props.portfoliosState.portfolios.find((it) => it.profile_id === this.props.profileState.details?.id)
      this.setState({ userHasPortfolio: !!found })
    }

    this.props.toggleOverlay(false)
  }

  handleCreatePortfolio = async () => {
    if (this.userLoggedIn) {
      console.log('Creating portfolio')

      this.setState({ creatingPortfolio: true })

      const res = await API.put('tba21', 'portfolios', { body: { profileId: this.props.profileState.details?.id } })
      if (!res.success) this.setState({ errorMessage: 'Sorry, something went wrong when creating your portfolio' }) 
      
      this.setState({ creatingPortfolio: false })

      this.props.fetchPortfolios()
      this.props.history.push(portfolioURL(this.props.profileState.details?.id as string))
    } else {
      this.props.history.push('/login')
    }
  }

  onSearchClick = () => {
    this.props.history.push('/portfolios/search')
    console.log(this.props);
    
  }

  render() {
    const halfLength = Math.ceil(this.props.portfoliosState.portfolios.length / 2)
    const shuffledPortfolios = shuffleArray(this.props.publicPortfolios)
    const portfoliosTopRow = shuffledPortfolios.slice(0, halfLength)
    const portfoliosBottomRow = shuffledPortfolios.slice(halfLength)

    return (
      <div className="portfolios">
        { (this.state.errorMessage || this.props.portfoliosState.errorMessage) &&
          <ErrorMessage message={this.state.errorMessage || this.props.portfoliosState.errorMessage} sticky />
        }
        { this.state.successMessage && (
          <SuccessMessage message={ this.state.successMessage } sticky />
        ) }
        <RollingHeader/>

        <section className="portfolios__intro">
          <div className="portfolios__intro-text">
            <h1 className="mb-4">〜 Liquid portfolios</h1>
            <p>See profiles of Ocean-Archive.org users</p>
          </div>
          <div className="portfolios__intro-buttons">
            <button className="button" onClick={ this.onSearchClick }>Search</button>
            { this.state.userHasPortfolio
              ? <Link to={ portfolioURL(this.props.profileState.details?.id || '') } className="button">My Portfolio</Link>
              : <button
                  className={classNames('button', { 'button--spinning': this.state.creatingPortfolio })}
                  onClick={ this.handleCreatePortfolio }
                >
                  Create portfolio
                </button>
            }
          </div>
        </section>

        { this.props.match.path === '/portfolios/search'
          ? <section>
              <PortfolioSearch />
              <ThemeSwitch />
            </section>
          : <section className="portfolios__bubbles theme-colors">
              <div>
                <div className="portfolios__bubbles-top">
                  { portfoliosTopRow?.map((portfolio) => (
                    <Bubble key={ portfolio.id } portfolio={ portfolio } />
                  ))}
                </div>
                <div className="portfolios__bubbles-bottom">
                  { portfoliosBottomRow?.map((portfolio) => (
                    <Bubble key={ portfolio.id } portfolio={ portfolio } />
                  ))}
                </div>
              </div>
              <ThemeSwitch />
            </section>
        }
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    publicPortfolios: state.portfolios.portfolios?.filter(it => it.profile.public_profile),
    portfoliosState: state.portfolios,
    profileState: state.profile
  }
}

export default withRouter(
  connect(mapStateToProps, { fetchPortfolios, toggleOverlay })(Portfolios)
)
