import * as React from 'react'
import { connect } from 'react-redux'
import { API, a } from 'aws-amplify'
import { State as ProfileStoreState } from 'reducers/user/viewProfile'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { BsPlusLg } from 'react-icons/bs'

import { Alerts, ErrorMessage, WarningMessage } from '../../utils/alerts'
import { Profile } from '../../../types/Profile'
import { initializeProfileData } from 'actions/user/viewProfile'
import { addFileToItem } from 'actions/items/viewItem'
import { AuthContext } from 'providers/AuthProvider'
import { getStoriesAndTotalStoriesInDatabase } from 'REST/story'

import { Item } from 'types/Item'
import { Collection } from 'types/Collection'
import { Portfolio } from 'types/Portfolio'

import RollingHeader from 'components/layout/RollingHeader'
import PortfolioContent from './PortfolioContent'
import PortfolioHeader from './PortfolioHeader'
import PortfolioAbout from './PortfolioAbout'
import TabSwitch from 'components/utils/TabSwitch'
import EditProfile from 'components/user/profile/Profile'
import DialogModal from 'components/modals/DialogModal'
import LargeModal from 'components/modals/LargeModal'

import config from 'config'

import './PortfolioDetail.scss'

interface Props extends RouteComponentProps, Alerts {
  profile: Profile
  content: (Item | Collection)[]
  favourites: (Item | Collection)[]
  initializeProfileData: Function
  isItemsAndCollectionsLoading: boolean
  isFavouriteItemsAndCollectionsLoading: boolean
  isFavouriteItemsAndCollectionsLoaded: boolean
  fetchedAllItemsAndCollections: boolean
  isFavouriteStoriesLoading: boolean
  currentTheme: string
  userChosenTheme: boolean
}

interface State {
  errorMessage: string
  activeTab: string
  portfolio: Portfolio | null
  profileEditorOpen: boolean
  deleteDialogOpen: boolean
  loading: boolean
}

class PortfolioDetail extends React.Component<Props, State> {
  static contextType = AuthContext
  tabs = [
    { label: 'About', value: 'about' },
    { label: 'Content', value: 'content' },
    { label: 'Favourites', value: 'favourites' }
  ]
  profileId: string = ''
  canEdit: boolean = false

  constructor(props: any) {
    super(props)
    this.state = {
      errorMessage: '',
      activeTab: 'about',
      portfolio: null,
      profileEditorOpen: false,
      deleteDialogOpen: false,
      loading: false
    }
  }

  componentDidMount() {
    const routeParams = this.props.match.params as any

    this.setState({ activeTab: routeParams.tab || 'about' })

    this.profileId = routeParams.profileId
    this.init()
  }

  componentDidUpdate(prevProps: any ) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.init()
    }

    this.canEdit = this.context.uuid === this.props.profile?.cognito_uuid
  }

  init = async (refresh?) => {
    if (!this.profileId) {
      this.setState({ errorMessage: 'Sorry, no profile found.' })
      return
    }

    this.setState({ loading: true })

    const data = !!this.profileId && await Promise.all([
      API.get('tba21', 'portfolios', { queryStringParameters: { profileId: this.profileId } }),
      this.props.initializeProfileData(this.profileId, refresh)
    ])

    const portfolio = data[0]?.data

    if (this.props.profile?.id && portfolio) { // Check if both profile and portfolio exists
      const ripples = await this.fetchRipples(portfolio.ripples)
      portfolio.fetched_ripples = ripples
      this.setState({ portfolio })
    } else {
      this.setState({ errorMessage: 'Sorry, no portfolio found.' })
    }

    this.setState({ loading: false })
  }

  update = async (attr: string, value: any) => {
    const path = ['biography'].includes(attr) ? 'profiles' : 'portfolios'
    const data = { body: { profileId: this.profileId, [attr]: value } }

    try {
      const result = await API.patch('tba21', path, data)
      if (!result.success) throw new Error()
      else this.setState({ errorMessage: '' })
    } catch {
      this.setState({ errorMessage: 'Sorry, something went wrong' })
    }
  }

  fetchRipples = async (list: any[]) => {
    if (!list?.length) return []

    const items = list.filter(it => it.type === 'item')
    const collections = list.filter(it => it.type === 'collection')
    const stories = list.filter(it => it.type === 'story')

    const [ fetchedItems, fetchedCollections, fetchedStories ] = await Promise.all([
      items.length && API.get('tba21', 'items/getMultiple', { queryStringParameters: { ids: items.map(it => it.id).join(',') } }),
      collections.length && API.get('tba21', 'collections/getMultiple', { queryStringParameters: { ids: collections.map(it => it.id).join(',') } }),
      stories.length && getStoriesAndTotalStoriesInDatabase({ include: stories.map(it => it.id), sticky: false, perPage: 100, page: 1 })
    ])

    const itemsWithFile = await Promise.all( fetchedItems?.data?.map((it: any) => addFileToItem(it)) || [] )
    const all = [
      ...itemsWithFile,
      ...(fetchedCollections.data || []),
      ...(fetchedStories && fetchedStories?.stories || [])
    ]

    return list.map(it => it.id
      ? all.find(ripple => Number(ripple.id) === Number(it.id))
      : it
    )
  }

  changeTab = (tab: string) => {
    this.setState({ activeTab: tab })
    window.history.replaceState(null, '', `/portfolios/${this.profileId}/${tab}`)
  }

  deletePortfolio = async () => {
    try {
      const result = await API.del('tba21', 'portfolios', { body: { profileId: this.profileId } })
      if (!result.success) throw new Error()
      else this.props.history.push('/portfolios', { successMessage: 'Your portfolio has been deleted' })
    } catch {
      this.setState({ errorMessage: 'Sorry, something went wrong' })
    }
  }

  render() {
    const possibleFirstName = this.props.profile?.full_name?.split(' ')[0]
    const filteredData = this.props.content?.filter(it => {
      return !( typeof it.title === 'string' && it.title?.startsWith('Banner') )
    }) || []

    return (
      <div id="page-portfolio-detail">
        <RollingHeader absolute noMargin />
        <ErrorMessage sticky message={ this.state.errorMessage || this.props.errorMessage } />
        <WarningMessage sticky message={
          this.props.profile?.public_profile || !this.canEdit
            ? undefined
            : <>Your portfolio will not be visible to others until you
                <a href="" onClick={ (e) => {
                  e.preventDefault()
                  this.setState({ profileEditorOpen: true })
                } }> make your profile public</a>.</>
        } />

        <div className={`portfolio-detail ${this.props.userChosenTheme ? this.props.currentTheme : ' theme-light'}`}>
          <PortfolioHeader
            profile={ this.props.profile }
            portfolio={ this.state.portfolio }
            activeTab={ this.state.activeTab }
            canEdit={ this.canEdit }
            onTabChange={ this.changeTab }
            openProfileEditor={ () => this.setState({ profileEditorOpen: true }) }
            onDelete={ () => this.setState({ deleteDialogOpen: true }) }
          />

          { this.state.portfolio && (
            <div className="portfolio-detail__content theme-colors">
              <div className="mobile-only mt-5 mb-5">
                <TabSwitch
                  tabs={ this.tabs }
                  activeTab={ this.state.activeTab }
                  onTabChange={ this.changeTab }
                />
              </div>
              { this.state.activeTab === 'about' &&
                <PortfolioAbout
                  profile={ this.props.profile }
                  portfolio={ this.state.portfolio }
                  userItems={ filteredData }
                  update={ this.update }
                  onError={ msg => this.setState({ errorMessage: msg }) }
                  canEdit={ this.canEdit }
                  fetchingData={ this.state.loading }
                />
              }
              { this.state.activeTab === 'content' &&
                ( false
                  ? <PortfolioContent data={ filteredData } title={ `${possibleFirstName}'s Content` } />
                  : <div className="portfolio-detail__content-empty">
                    { this.canEdit
                      ? <>
                          You have not contributed any content yet.
                          <div>
                            <Link to="/contributor/items/add" className="button button--small button--w-icon">
                              <BsPlusLg size={16} /> Create item
                            </Link>
                            <Link to="/contributor/collections/add" className="button button--small button--w-icon">
                              <BsPlusLg size={16} /> Create collection
                            </Link>
                            <a href={ config.urls.WP_URL + 'wp-admin/post-new.php' } className="button button--small button--w-icon">
                              <BsPlusLg size={16} /> Create journey
                            </a>
                          </div>
                        </>
                      : `${possibleFirstName} has not contributed any content yet.`
                    }
                    </div>
                )
              }
              { this.state.activeTab === 'favourites' &&
                ( !!this.props.favourites.length
                  ? <PortfolioContent data={ this.props.favourites } title={ `${possibleFirstName}'s Starred` } />
                  : <div className="portfolio-detail__content-empty">
                      { this.canEdit
                        ? 'You have not favourited any content yet.'
                        : `${possibleFirstName} has not favourited any content yet.`
                      }
                    </div>
                )
              }
            </div>
          ) }
        </div>

        <LargeModal
          title="Edit profile details"
          onClose={ () => {
              this.setState({ profileEditorOpen: false })
              this.init(true)
          } }
          open={ this.state.profileEditorOpen }
        >
          <EditProfile deletingAccount={false} favouriteIsLoading={false} />
        </LargeModal>

        <DialogModal
          title="Do you really want to delete your portfolio?"
          onClose={ () => this.setState({ deleteDialogOpen: false }) }
          open={ this.state.deleteDialogOpen }
        >
          <p>This will only delete your portfolio page. Your Ocean Archive profile and contributions will not be affected.</p>
          <p>This action cannot be undone.</p>
          <div className="text-center pt-1">
            <button className="button button--small mr-3" onClick={ () => this.setState({ deleteDialogOpen: false }) }>
              Cancel
            </button>
            <button className="button button--small button--red" onClick={ this.deletePortfolio }>
              Delete
            </button>
          </div>
        </DialogModal>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    errorMessage: state.viewProfile.errorMessage,
    profile: state.viewProfile.profile,
    content: [...state.viewProfile.items, ...state.viewProfile.collections, ...state.viewProfile.ownStories],
    favourites: [...state.viewProfile.favouriteItems, ...state.viewProfile.favouriteCollections, ...state.viewProfile.favouriteStories],
    isFavouriteItemsAndCollectionsLoaded: state.viewProfile.isFavouriteItemsAndCollectionsLoaded,
    isItemsAndCollectionsLoading: state.viewProfile.isItemsAndCollectionsLoading,
    isFavouriteStoriesLoading: state.viewProfile.isFavouriteStoriesLoading,
    fetchedAllItemsAndCollections: !state.viewProfile.collectionsHasMore && !state.viewProfile.itemsHasMore,
    currentTheme: state.global.theme,
    userChosenTheme: state.global.userChosenTheme
  }
}

export default withRouter(
  connect(mapStateToProps, { initializeProfileData })(PortfolioDetail)
)
