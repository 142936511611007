import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import StoryList from 'components/story/StoryList';
import StorySearches from '../story/StorySearches';
import 'styles/components/story.scss';
import { debounce, throttle } from 'lodash';
import { fetchStoriesInitial } from '../../actions/story/storyList';
import { connect } from 'react-redux';
import { StoryListState } from '../../reducers/story/storyList';
import { WP_REST_API_Terms, WP_REST_API_Tags } from 'wp-types';
import StoryHero from 'components/story/StoryHero';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaSearch, FaArrowUp } from 'react-icons/fa';
import Footer from 'components/layout/Footer';
import ThemeSwitch from 'components/utils/ThemeSwitch';
import { setTheme } from 'actions/global'

type StoriesProps = {
  fetchStoriesInitial: Function;
  categoryById: StoryListState['categoryById'];
  tagById: StoryListState['tagById'];
  authorById: StoryListState['authorById'];
  setThemeState: Function;
};

// eslint-disable-next-line react/prop-types
const StoryHeader = ({ toggleShowSearchMobile, isSticky, scrollToHero }) => {
  return (
    <div className="stories-headline-wrapper">
      <div className="stories-headline-inner">
        <StoryHeaderInside
          toggleShowSearchMobile={toggleShowSearchMobile}
          scrollToHero={scrollToHero}
          isSticky={isSticky}
        />
      </div>
      {/* This hidden component is needed to keep the component layout persistent while scrolled */}
      <div className="stories-hidden">
        <StoryHeaderInside
          toggleShowSearchMobile={toggleShowSearchMobile}
          scrollToHero={scrollToHero}
          isSticky={false}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const StoryHeaderInside = ({ toggleShowSearchMobile,  isSticky, scrollToHero }) => {
  return (
    <div
      className={`stories-headline ${
        isSticky ? 'stories-headline--sticky' : ''
      }`}
    >
      <div
        className={`stories-scrolltop-mobile ${
          isSticky ? 'stories-scrolltop-mobile--sticky' : ''
        }`}
      >
        <FaArrowUp size={24} onClick={scrollToHero} />
      </div>
      <span style={{ fontFamily: 'Amare Medium, sans-serif' }}>
        ~ Dive into journeys
      </span>
      <div className="stories-search-mobile">
        <FaSearch
          size={24}
          onClick={() => toggleShowSearchMobile((prevState) => !prevState)}
        />
      </div>
    </div>
  );
};

const Stories: React.FC<StoriesProps> = ({
  fetchStoriesInitial, // eslint-disable-line @typescript-eslint/no-shadow
  categoryById,
  tagById,
  setThemeState
}) => {
  const heroRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);
  const [isFilterSticky, setIsFilterSticky] = useState(false);
  const [showSearchMobile, toggleShowSearchMobile] = useState(false);

  useLayoutEffect(() => {
    document.title = 'Journeys | Ocean Archive'

    let elem = heroRef.current;
    const scrollHandler = () => {
      // element is shown on window
      if (filterRef && elem && elem.getBoundingClientRect().bottom <= 0) {
        setIsFilterSticky(true);
      } else {
        setIsFilterSticky(false);
      }
    };

    const throttledScrollHandler = throttle(scrollHandler, 100);

    if (elem) {
      window.addEventListener('scroll', throttledScrollHandler);
    }
    return () => window.removeEventListener('scroll', throttledScrollHandler);
  }, []);

  const [title, setTitle] = useState('');
  const [orderBy, setOrderBy] = useState<'author' | 'title' | 'date'>('date');
  const [orderAuthor, setOrderAuthor] = useState<'asc' | 'desc'>('asc');
  const [orderTitle, setOrderTitle] = useState<'asc' | 'desc'>('asc');
  const [selectedCategoryIds, _setSelectedCategoryIds] = useState<Set<number>>(
    new Set()
  );
  const [selectedTagIds, _setSelectedTagIds] = useState<Set<number>>(new Set());

  const setSelectedCategoryIds = useCallback(
    (id) => {
      let newSet = new Set(selectedCategoryIds);
      if (newSet.has(id)) {
        newSet.delete(id);
       } else {
         newSet.add(id);
       }
      _setSelectedCategoryIds(newSet);
    },
    [selectedCategoryIds]
  );

  const setSelectedTagIds = useCallback(
    (id) => {
      let newSet = new Set(selectedTagIds);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      _setSelectedTagIds(newSet);
    },
    [selectedTagIds]
  );

  const debouncedFetchStoriesInitial = useCallback(
    debounce((...args) => fetchStoriesInitial(...args), 250),
    []
  );

  useEffect(() => {
    let order =
      orderBy === 'author'
        ? orderAuthor
        : orderBy === 'title'
        ? orderTitle
        : 'desc';

    debouncedFetchStoriesInitial({
      title,
      order,
      orderBy,
      categoryIds: Array.from(selectedCategoryIds),
      tagIds: Array.from(selectedTagIds),
    });
  }, [
    title,
    orderAuthor,
    orderTitle,
    orderBy,
    debouncedFetchStoriesInitial,
    selectedCategoryIds,
    selectedTagIds,
  ]);

  const selectedCategories: WP_REST_API_Terms = useMemo(() => {
    return Array.from(selectedCategoryIds)
      .filter((cat) => categoryById[cat])
      .map((categoryId) => {
        let category = categoryById[categoryId];
        return category;
      });
  }, [categoryById, selectedCategoryIds]);

  const selectedTags: WP_REST_API_Tags = useMemo(
    () =>
      Array.from(selectedTagIds)
        .filter((tag) => tagById[tag])
        .map((tagId) => {
          return tagById[tagId];
        }),
    [tagById, selectedTagIds]
  );
  return (
    <div className="stories">
      <StoryHero heroRef={heroRef} />
      <div className="stories__wrapper">
        <ThemeSwitch />
        <StorySearches
          isSticky={isFilterSticky}
          wrapperRef={filterRef}
          title={title}
          setTitle={setTitle}
          selectedCategories={selectedCategories}
          setSelectedCategoryIds={setSelectedCategoryIds}
          selectedTags={selectedTags}
          setSelectedTagIds={setSelectedTagIds}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderTitle={orderTitle}
          orderAuthor={orderAuthor}
          setOrderAuthor={setOrderAuthor}
          setOrderTitle={setOrderTitle}
          showSearchMobile={!!showSearchMobile}
          toggleShowSearchMobile={toggleShowSearchMobile}
        />
        <div className={'stories__list-wrapper'}>
          <StoryHeader
            toggleShowSearchMobile={toggleShowSearchMobile}
            isSticky={false}
            scrollToHero={() => {
              let yPos =
                (heroRef.current?.offsetTop ?? 0) +
                (heroRef.current?.offsetHeight ?? 0);
              toggleShowSearchMobile(false);
              window.scrollTo(0, yPos - 42);
            }}
          />
          <StoryList
            setSelectedCategoryIds={setSelectedCategoryIds}
            setSelectedTagIds={setSelectedTagIds}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connect(
  (state: { storyList: StoryListState }) => ({
    categoryById: state.storyList.categoryById,
    tagById: state.storyList.tagById,
    authorById: state.storyList.authorById,
  }),
  { fetchStoriesInitial, setThemeState: setTheme }
)(Stories);
