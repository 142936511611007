import { useEffect, useState, useRef } from 'react'
import { Spinner } from 'reactstrap'
import { Link } from 'react-router-dom'
import { FiEdit } from 'react-icons/fi'
import { BsTrash } from 'react-icons/bs'
import classNames from 'classnames'

import { itemURL, collectionURL, storyURL } from 'urls'
import { getItemType, tryGetImage } from 'utils'
import HtmlDescription from 'components/utils/HtmlDescription'
import Transition from 'components/utils/Transition'
import RipplesLayoutSvg from './RipplesLayoutSvg'

import './Ripples.scss'

export const layouts = {
  'one-only': 1,
  'one-by-one': 2,
  'one-by-two': 3,
  'two-by-two': 4,
  'one-by-four': 5,
  'two-by-four': 6,
  'four-by-four': 8
}

interface Props {
  items: any[]
  layout?: string | null
  editMode?: boolean
  isLoading?: boolean
  onLayoutSelected?: Function
  onSelectItem?: Function
  onDeleteItem?: Function
}

const Ripples = ({ items, layout, editMode, isLoading, onLayoutSelected, onSelectItem, onDeleteItem }: Props) => {
  const _items = items.map((it, i) => ({ ...it, position: i }))
  const _layout = layout || 'one-by-one'

  const [displayLayouts, setDisplayLayouts] = useState<string | boolean>(false)
  const contentRef = useRef(null)
  const imgOverlay = useRef(null)

  const leftItemsCount =
    _layout?.startsWith('one') ? 1
      : _layout?.startsWith('two') ? 2 : 4

  const rightItemsCount =
    _layout?.endsWith('one') ? 1
      : _layout?.endsWith('two') ? 2
        : _layout?.endsWith('only') ? 0 : 4

  const leftItems = _items?.slice(0, leftItemsCount) || []
  const rightItems = _items?.slice(leftItemsCount, leftItemsCount + rightItemsCount) || []

  const handleItemClick = (e, index) => {
    if (editMode) {
      e.preventDefault()
      onSelectItem?.(index)
    }
  }

  useEffect(() => {
    setDisplayLayouts(editMode ? 'enter-from' : 'leave-to')
    editMode
      ? window.requestAnimationFrame(() => setDisplayLayouts(true))
      : setTimeout(() => setDisplayLayouts(false), 300)
  }, [editMode])

  // const createRipples = () => {
  //   setTimeout(() => {
  //     const rips = new WebGLRipples(contentRef.current as any, {
  //       resolution: 512,
  //       dropRadius: 10,
  //       perturbance: 0.02,
  //     })
  //   }, 1000)
  // }

  // useEffect(() => {
  //   if (contentRef.current) {
  //     createRipples()
  //   }
  // }, [items])

  const Item = ({ item }: { item: any }) => {
    const itemType = getItemType(item)
    const link =
      ( itemType === 'item' && itemURL(item.id) ) ||
      ( itemType === 'collection' && collectionURL(item.id) ) ||
      ( itemType === 'story' && storyURL(item.slug) )

    const title = itemType === 'story' ? item.title?.rendered : item?.title
    const description = itemType === 'story' ? item.excerpt?.rendered : item?.description

    return (
      <Link
        to={ link }
        className={ classNames('ripples__item', { empty: !item.id }) }
        onClick={ (e) => handleItemClick(e, item.position) }
      >
        <div className="ripples__item-image">
          <img src={ tryGetImage(item, 960) } alt="" />
        </div>
        <div className="ripples__item-content">
          {/* <div className="ripples__item-actions">
          </div> */}
          <h3>{ title }</h3>
          <div className="ripples__item-description">
            <HtmlDescription description={ description } />
          </div>
        </div>
        { editMode && (
          <div className="ripples__item-edit">
            <FiEdit size={ 28 } color="white" /> 
            <BsTrash size={ 28 } color="white" onClick={ (e) => {
              console.log('delete', item.position);
              e.preventDefault()
              e.stopPropagation()
              onDeleteItem?.(item.position)
            } } /> 
          </div>
        )}
      </Link>
    )
  }

  const EmptyItem = ({ item }: { item: any }) => {
    return (
      <div className="ripples__item empty" onClick={ (e) => handleItemClick(e, item.position) }>
        <div className="ripples__item-image" />
        { editMode && (
          <div className="ripples__item-edit">
            <FiEdit size={ 28 } color="white" /> 
          </div>
        )}
      </div>
    )
  }

  const classes = classNames(
    `ripples ripples--${_layout}`,
    { 'ripples--editing': editMode },
    { loading: isLoading}
  )

  return <>
    <div className={ classes }>
      <Transition show={ !!editMode }>
        <div className={ `ripples__layouts ${displayLayouts || ''}` }>
          { Object.keys(layouts).map((it) => (
            <div
              key={ it }
              className={ _layout === it ? 'active' : '' }
              onClick={ () => onLayoutSelected?.(it) }
              role="button"
            >
              <RipplesLayoutSvg name={ it } />
            </div>
          )) }
        </div>
      </Transition>

      <div ref={ contentRef } className={ classNames('ripples__content', { 'single-col': !rightItemsCount }) }>
        <div className={`ripples__left count-${leftItemsCount}`}>
          { leftItems?.map((it, i) => (
            it.id
              ? <Item item={ it } key={ i } />
              : <EmptyItem item={ it } key={ i } />
          )) }
        </div>
        <div className={`ripples__right count-${rightItemsCount}`}>
          { rightItems?.map((it, i) => (
            it.id
              ? <Item item={ it } key={ i } />
              : <EmptyItem item={ it } key={ i } />
          )) }
        </div>
      </div>

      { isLoading && <div className="loader"><Spinner /></div> }

      {/* <img ref={ imgOverlay } src="" className="ripples__overlay" alt="" /> */}
    </div>
  </>
}

export default Ripples
